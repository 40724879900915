import React from "react";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row align-items-center">
          {/* Copy-right section */}
          <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
            <p>&copy; 2024 All Rights Reserved. Webseller</p>
          </div>

          {/* Social Media Icons */}
          <div className="col-md-6 d-flex justify-content-center justify-content-md-end">
            <ul className="list-unstyled d-flex mb-0">
              <li className="mx-2">
                <a
                  href="https://wa.me/yourphonenumber"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="assets/img/instagram.png"
                    alt="Instagram"
                    style={{ width: "40px", height: "40px" }}
                  />
                </a>
              </li>
              <li className="mx-2">
                <a
                  href="https://wa.me/yourphonenumber"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="assets/img/facebook.png"
                    alt="Facebook"
                    style={{ width: "40px", height: "40px" }}
                  />
                </a>
              </li>
              <li className="mx-2">
                <a
                  href="https://wa.me/yourphonenumber"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="assets/img/twitter.png"
                    alt="Twitter"
                    style={{ width: "40px", height: "40px" }}
                  />
                </a>
              </li>
              <li className="mx-2">
                <a
                  href="https://wa.me/yourphonenumber"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="assets/img/whatsapp.png"
                    alt="WhatsApp"
                    style={{ width: "40px", height: "40px" }}
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
